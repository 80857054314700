import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'home',
		meta: { title: 'Cleanup Rostock' },
		component: HomeView
	},

	{
		path: '/unternehmen',
		name: 'company',
		meta: { title: 'Unternehmen - Cleanup Rostock' },
		component: () => import(/* webpackChunkName: "about" */ '../views/CompanyView.vue')
	},

	{
		path: '/leistungen',
		name: 'services',
		meta: { title: 'Leistungen - Cleanup Rostock' },
		component: () => import(/* webpackChunkName: "about" */ '../views/ServicesView.vue')
	},

	{
		path: '/jobs',
		name: 'jobs',
		meta: { title: 'Jobs - Cleanup Rostock' },
		component: () => import(/* webpackChunkName: "about" */ '../views/JobsView.vue')
	},

	{
		path: '/kontakt',
		name: 'contact',
		meta: { title: 'Kontakt - Cleanup Rostock' },
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
	},

	{
		path: '/impressum',
		name: 'impressum',
		meta: { title: 'Impressum - Cleanup Rostock' },
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/ImpressumView.vue')
	},

	{
		path: '/datenschutz',
		name: 'datenschutz',
		meta: { title: 'Datenschutz - Cleanup Rostock' },
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "about" */ '../views/PrivacyView.vue')
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach((to, from, next) => {
	document.title = String(to.meta.title);
	next();
});

export default router
