<template>

	<div class="cover-image">
		<img src="@/assets/Gebaudereinigung.jpg" alt="Cover Bild">
	</div>

	<div class="page-title">Willkommen</div>

	<div class="text-block">
		<p>Herzlich willkommen auf der Website des Gebäudereinigungsunternehmens CleanUp - Ihr Imageservice
			für saubere und zuverlässige Gebäudereinigung in Rostock und Umgebung. Lernen Sie unser umfassendes
			Leitungsangebot rund um Unterhaltsreinigung, Büroreinigung, Treppenhausreinigung, Glas- und
			Glasfassadenreinigung, Grünflächenpflege u.v.m. kennen!</p>
		<a href="/leistungen"><i class="fa-solid fa-circle-right"></i> zu unseren Leistungen</a>
	</div>

	<div class="info-block">
		<h2>Eine saubere Sache</h2>
		<div class="col-2">
			Bitte senden Sie uns Ihre individuelle Anfrage für ein konkret auf Sie zugeschnittenes Angebot aus unserem umfangreichen Leistungsspektrum.
			<a href="/kontakt#formular"> <div class="primary-button"> <i class="fa-solid fa-hand-point-right"></i> Anfrage </div> </a>
		</div>
	</div>

</template>


<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
	name: 'HomeView',
});
</script>

