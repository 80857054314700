<template>

	<div id="header">
		<div id="site-logo">
			<a href="/">
				<img src="@/assets/Logo_CleanUp.gif" alt="Logo Cleanup Rostock">
			</a>
		</div>

		<nav id="header-menu" key="header-menu">
			<router-link to="/"> <i class="fa-solid fa-house"></i> Home</router-link>
			<router-link to="/unternehmen"> <i class="fa-solid fa-layer-group"></i> Unternehmen</router-link>
			<router-link to="/leistungen"> <i class="fa-solid fa-list-check"></i> Leistungen</router-link>
			<router-link to="/jobs"> <i class="fa-solid fa-helmet-safety"></i> Jobs</router-link>
			<router-link to="/kontakt"> <i class="fa-solid fa-at"></i> Kontakt</router-link>
		</nav>

		<nav v-if="this.menu_opened" id="mobile-menu" key="mobile-menu">
			<router-link to="/"> <i class="fa-solid fa-house"></i> Home</router-link>
			<router-link to="/unternehmen"> <i class="fa-solid fa-layer-group"></i> Unternehmen</router-link>
			<router-link to="/leistungen"> <i class="fa-solid fa-list-check"></i> Leistungen</router-link>
			<router-link to="/jobs"> <i class="fa-solid fa-helmet-safety"></i> Jobs</router-link>
			<router-link to="/kontakt"> <i class="fa-solid fa-at"></i> Kontakt</router-link>
		</nav>

		<div class="menu-burger" v-on:click="toggleBurgerMenu()">
			<span key="menu-open" v-if="!this.menu_opened">
				<i id="menu-open" class="fa-solid fa-bars"></i>
			</span>
			<span key="menu-close" v-if="this.menu_opened">
				<i id="menu-close" class="fa-solid fa-xmark"></i>
			</span>

		</div>
	</div>

	<div class="page-content">
		<router-view />
	</div>


	<div id="footer">
		<div class="footer-item"><router-link to="/impressum"> Impressum </router-link></div>
		<div class="footer-item">|</div>
		<div class="footer-item"><router-link to="/datenschutz"> Datenschutz </router-link></div>
	</div>

</template>


<script>

export default {
	data ()
	{
		return {
			menu_opened: false,
		}
	},

	watch: {
		$route (to, from)
		{
			this.menu_opened = false;
		}
	},

	mounted ()
	{
		window.addEventListener("resize", this.onWindowResize);
	},

	methods:
	{
		toggleBurgerMenu ()
		{
			if (this.menu_opened)
			{
				this.menu_opened = false;
			}
			else
			{
				this.menu_opened = true;
			}
		},

		onWindowResize ()
		{
			if (window.innerWidth >= 768)
			{
				this.menu_opened = false;
			}
		},
	}
}
</script>


<style>
body
{
	margin: unset;
}

a
{
	text-decoration: none;
}

h2
{
	margin: unset;
	margin-bottom: 12px;
	font-weight: 500;
}

ul
{
	list-style: square url("@/assets/Jobs_dot.gif");
}

#app
{
	font-size: 18px;
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	--main-green: rgb(15, 162, 64);
	--main-green-darker: rgb(0, 71, 24);
}

#header
{
	display: flex;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.85);
	position: fixed;
	justify-content: center;
	top: 0;
	-webkit-transition: height 0.5s ease-in-out;
	-moz-transition: height 0.5s ease-in-out;
	-o-transition: height 0.5s ease-in-out;
	transition: height 0.5s ease-in-out;
}

#site-logo,
#site-logo img
{
	max-height: 82px;
}

#header-menu
{
	display: flex;
	flex-direction: row;
	flex-grow: 1;
	justify-content: flex-end;
	gap: 15px;
	max-width: 1000px;
	padding-left: 10px;
	padding-right: 10px;
}

#mobile-menu
{
	display: none;
}

#header-menu a
{
	padding-top: 30px;
	padding-bottom: 30px;
	font-size: 18px;
	font-weight: bold;
	color: var(--main-green-darker);
	text-decoration: none;
}

#mobile-menu a
{
	font-weight: bold;
	color: var(--main-green-darker);
	text-decoration: none;
}

#header-menu a.router-link-exact-active,
#mobile-menu a.router-link-exact-active
{
	color: var(--main-green);
}

#mobile-menu
{
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.page-content
{
	max-width: 1000px;
	margin: auto;
	padding-top: 80px;
	padding-bottom: 75px;
}

.cover-image img
{
	width: 100%;
}

.page-title
{
	font-size: 28px;
	font-weight: 500;
	text-transform: uppercase;
	text-align: center;
	margin-top: 32px;
	margin-bottom: 32px;
	border-bottom: 2px solid lightgray;
}

.col-2,
.col-3
{
	display: flex;
	flex-direction: row;
	gap: 15px;
}

.text-block
{
	text-align: justify;
	margin-top: 10px;
	margin-bottom: 10px;
}

.text-block a
{
	font-weight: bold;
	color: green;
	text-decoration: none;
}

.info-block
{
	text-align: left;
	padding: 20px;
	border-left: 4px solid var(--main-green);
	background-color: #dddddd;
	margin-top: 10px;
	margin-bottom: 10px;
}

.info-block h2
{
	text-transform: uppercase;
}

#footer
{
	width: 100%;
	display: flex;
	position: fixed;
	flex-direction: row;
	background-color: var(--main-green);
	justify-content: flex-end;
	bottom: 0;
}

.footer-item,
.footer-item a
{
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 15px;
	padding-bottom: 15px;
	color: white;
	font-weight: 500;
	text-decoration: none;
}

.menu-burger
{
	cursor: pointer;
	padding: 14px;
}

.menu-burger svg
{
	font-size: 36px;
	text-align: center;
}

@media all and (max-width: 1000px)
{
	.page-content
	{
		padding-left: 10px;
		padding-right: 10px;
	}

	.col-3
	{
		flex-direction: column;
	}
}

@media all and (max-width: 767px)
{
	#site-logo img
	{
		max-height: 66px;
	}

	#header
	{
		display: flex;
		top: 0;
		justify-content: space-between;
		background-color: rgba(255, 255, 255, 0.95);
		border-bottom: 4px solid var(--main-green);
	}

	#header-menu
	{
		display: none;
	}

	#mobile-menu
	{
		display: flex;
		flex-direction: column;
	}
}

@media all and (min-width: 768px)
{
	#header
	{
		display: flex;
		justify-content: space-between;
	}

	.menu-burger
	{
		display: none;
	}
}

@media all and (max-width: 550px)
{
	.col-2
	{
		flex-direction: column;
	}
}

@media all and (max-width: 360px)
{
	#site-logo img
	{
		display: none;
	}
}

.primary-button
{
	display: flex;
	height: 44px;
	background-color: var(--main-green);
	color: white;
	transition: background-color 0.5s ease-in-out;
	border-radius: 10px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 0px;
	padding-bottom: 0px;
	text-align: center;
	align-items: center;
	justify-content: center;
	align-self: end;
	font-weight: 600;
	cursor: pointer;
	gap: 5px;
}

.primary-button:hover
{
	background-color: var(--main-green-darker);
	transition: background-color 0.5s ease-in-out;
}
</style>
